import { PATHS } from 'constants/paths';
import { graphql, useStaticQuery } from 'gatsby';
import { formatFaqData } from 'utilities/contentful';

import { HUB_URLS } from '../constants';
import { getBlogPagePosts, resolveBlogPageContent } from '../utilities';

const content = resolveBlogPageContent({
    paragraphsCounters: { firstRow: 1, secondRow: 1 },
    quoteAuthor: {
        name: 'matt-sadowski',
        position: 'ceo-proptech-expert',
    },
});

export const useBlogProptech = (blogPosts, faqResult) => {
    const { industryLeadersPosts, posts } = getBlogPagePosts(
        blogPosts,
        'industry-leaders',
    );

    const descriptionLinksData = [
        {
            key: 'machineLearningLink',
            url: `${PATHS.SERVICES}/${PATHS.DATA_SCIENCE}`,
            component: 'gatsby-link',
        },
    ];

    const subInfoLinksData = [
        {
            key: 'proptechCompaniesLink',
            url: `${PATHS.BLOG}/top-10-fast-growing-proptech-companies-in-the-us-in-2023`,
            component: 'gatsby-link',
        },
    ];

    const firstRowLinksData = [
        {
            key: 'realEstateLink',
            url: `${PATHS.BLOG}/top-10-fast-growing-proptech-companies-in-the-uk-in-2023`,
            component: 'gatsby-link',
        },
        {
            key: 'toolsLink',
            url: `${PATHS.BLOG}/software-for-real-estate`,
            component: 'gatsby-link',
        },
    ];

    const secondRowLinksData = [
        {
            key: 'blockchainLink',
            url: `${PATHS.EXPERTISES}/${PATHS.NFT}`,
            component: 'gatsby-link',
        },
        {
            key: 'smartBuildingLink',
            url: `${PATHS.BLOG}/next-generation-property-management`,
            component: 'gatsby-link',
        },
    ];

    const images = useStaticQuery(
        graphql`
            query {
                firstRowImage: file(
                    relativePath: {
                        eq: "prop-tech-real-estate/real-estate-app.png"
                    }
                ) {
                    ...ImageCaseStudyConfig
                }
                secondRowImage: file(
                    relativePath: {
                        eq: "prop-tech-real-estate/real-estate-map.png"
                    }
                ) {
                    ...ImageCaseStudyConfig
                }
            }
        `,
    );

    const faqData = formatFaqData(faqResult);

    return {
        ...images,
        ...content,
        industryLeadersPosts,
        posts,
        hub: HUB_URLS.PROPTECH,
        faqData,
        descriptionLinksData,
        subInfoLinksData,
        firstRowLinksData,
        secondRowLinksData,
    };
};
